import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import { Store } from "@ngrx/store";

import { AppState } from "@app/app.reducer";
import * as objectNotesActions from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.actions";
import { debounceTime, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-create-note",
  templateUrl: "./create-note.component.html",
  styleUrls: ["./create-note.component.scss"],
})
export class CreateNoteComponent implements OnInit, OnDestroy {
  @Input() eaOid: string;

  @Output() isSubmitted = new EventEmitter<any>();

  form: FormGroup;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        title: [""],
        content: [""],
      },
      { validators: this.atLeastOneFieldValidator }
    );

    this.form.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.form.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  atLeastOneFieldValidator: ValidatorFn = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    const title = !!control.get("title")?.value;
    const content = !!control.get("content")?.value;
    return title || content ? null : { atLeastOneRequired: true };
  };

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.store.dispatch(
      objectNotesActions.createNoteRequest({
        eaOid: this.eaOid,
        body: this.form.value,
      })
    );
    this.isSubmitted.emit();
  }
}
